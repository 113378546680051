import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService) {}

  ngOnInit() {
    let customer;
    const regex = /https?:\/\//g;
    const origin = environment.redirectUrl.replace(regex, '');
    if (origin.includes('localhost')) {
      customer = 'localhost';
    }
    else {
      customer = origin.split('.')[0];
    }
    this.isIframe = window !== window.parent && !window.opener;
    document.title = `CLIV | ${customer.toUpperCase()}`

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    //console.log(this.loginDisplay)
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
