import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  private response: BehaviorSubject<{ msg: string, responseCode?: string }> =
    new BehaviorSubject<{ msg: string; responseCode: string }>({ msg: '', responseCode: '' });

  setResponse(msg: string, responseCode: string) {
    this.response.next({ msg, responseCode });
  }

  getResponse(): Observable<{ msg: string, responseCode?: string }> {
    return this.response.asObservable();
  }
}
