import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalRedirectComponent,
         MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from "@azure/msal-angular";
import { environment } from "environments/environment";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MsalUserService } from "./core/services/msa.service";
import { HttpService } from "./core/services/http.service";
import { CookieModule } from 'ngx-cookie';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel,
         PublicClientApplication } from "@azure/msal-browser";
import { InfoPageComponent } from './pages/info-page/info-page.component';
import { HttpRequestInterceptor } from "./core/interceptors/http.interceptor";
import { UserService } from "./core/services/user.service";
import { SortService } from "./core/services/sort.service";
import { LoaderService } from "./core/services/loader.service";
import { LoaderComponent } from "./shared/loader/loader.component";
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { VersionHistoryComponent } from './shared/version-history/version-history.component';
import { SentryErrorHandler } from "./core/services/sentry-error-handler.service";
import { ApiCacheService } from "./core/services/api-cache.service";
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.uiClienId,
      // authority:environment.teantId,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return { interactionType: InteractionType.Redirect, protectedResourceMap };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    InfoPageComponent,
    LoaderComponent,
    LandingPageComponent,
    VersionHistoryComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(AppRoutes, {
      // useHash: true,
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
      enableTracing: !environment.production
    }),
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
    HttpClientModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    CookieModule.forRoot()
  ],
  providers: [
    HttpService,
    UserService,
    SortService,
    MsalUserService,
    ApiCacheService,
    { provide: 'API_CACHE_STORAGE', useValue: sessionStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    LoaderService,
    {provide: ErrorHandler,
      useClass: SentryErrorHandler},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
