import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class StartGurad implements CanActivate {
  constructor(private router: Router) { }
  canActivate(): boolean {
    if (localStorage.getItem('tryLogin')) {
      return true;
    } else {
      this.router.navigateByUrl('/main');
      return false;
    }
  }
}