import { Injectable, EventEmitter } from '@angular/core';
import { API_KEYS } from 'app/common/constants';
import { HttpService } from './http.service';

@Injectable()
export class SortService {
  key: string;
  toggle = new EventEmitter<boolean>();
  constructor(private httpService: HttpService) { }
  toggleSort(isAscending: boolean, data, api) {
    if (isAscending) {
      data[API_KEYS.SORT_DIRECTION] = 1;
      this.toggle.emit(false);
    } else {
      data[API_KEYS.SORT_DIRECTION] = -1;
      this.toggle.emit(true);
    }
    return this.httpService.getData(api, data);
  }
}
