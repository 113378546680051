import { Component } from '@angular/core';
import {environment} from '../../../environments/environment'

@Component({
  moduleId: module.id,
  selector: 'footer-cmp',
  templateUrl: 'footer.component.html',
})

export class FooterComponent {
  environment = environment;
  test: Date = new Date();
}
