import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DEFAULT_PAGE, MESSAGES} from 'app/common/constants';
import { AuthUserService } from 'app/core/services/auth-user.service';
import { AuthError, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.css'],
})
export class InfoPageComponent implements OnInit {

  title;
  text = MESSAGES;
  notApproved: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router, private authUserService: AuthUserService,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(): void {
    if(this.router.url == '/not-approved') {
      this.notApproved = true;
      this.activatedRoute.data.subscribe(result => {
        this.title = result.title;
      });
    }

    if(localStorage.getItem('token')){
      this.router.navigateByUrl('/' + DEFAULT_PAGE).then(r=>null);
      return;
    }
    this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
    this.authService.acquireTokenSilent({scopes: ['User.Read']}).subscribe((result: AuthenticationResult) => {
      this.authUserService.login({ token: result.idToken, platform: 2, clientName: environment.clientName });
    },
      ((err) => {
        if (err instanceof AuthError && this.msalGuardConfig) {
          // fallback to interaction when silent call fails
          return this.authService.acquireTokenRedirect({scopes: ['User.Read']} as RedirectRequest);
        }
      }
      ));
  }
}
