import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class AlertService {
  obj;
  confirmAlert;
  toastObj;
  verifyObj;
  
  constructor(private toastr: ToastrService) {
    // Toast object init
    this.obj = {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      positionClass: "toast-top-right",
    };

    // confirm alert init
    this.confirmAlert = {
      type: '',
      title: '',
      text: '',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonClass: '',
      cancelButtonClass: '',
      buttonsStyling: true
    };
    this.verifyObj = {
      title: '',
      input: '',
      inputAttributes: { autocapitalize: 'off' },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: '',
      allowOutsideClick: () => !Swal.isLoading()
    };
  }

  /**
  * Verify popup
  * @param title
  * @param input
  * @param preconfirm
  */
  verifyPopup(title, input, preconfirm) {
    this.verifyObj.title = title;
    this.verifyObj.input = input;
    this.verifyObj.preConfirm = preconfirm;
    return Swal.fire(this.verifyObj);
  }

  toastShow(message) {
    this.toastr.show(message, '', {...this.obj, toastClass: 'alert alert-primary alert-with-icon'});
  }

  successToast(message) {
    this.toastr.success(message, '', {...this.obj, toastClass: 'alert alert-success alert-with-icon'});
  }

  errorToast(message) {
    this.toastr.error(message, '', {...this.obj, toastClass: 'alert alert-danger alert-with-icon'});
  }

  simplePopUp(message) {
    this.toastr.info(message, '', {...this.obj, toastClass: 'alert alert-info alert-with-icon'});
  }

  warningPopUp(message) {
    this.toastr.warning(message, '', {...this.obj, toastClass: 'alert alert-warning alert-with-icon'});
  }

  confirmPopUp(obj) {
    this.confirmAlert.title = obj.title;
    this.confirmAlert.type = obj.type;
    this.confirmAlert.text = obj.text;
    return Swal.fire(this.confirmAlert);
  }

  input(title: string, inputType: 'text' | 'textarea', placeholder?: string, text?: string) {
    return Swal.fire({
      title: title,
      text: text,
      input: inputType,
      inputPlaceholder: placeholder,
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true
    })
  }

//   textArea(title, prevMessage, placeholder) {
//     return Swal.fire({
//       title: title,
//       input: 'text',
//       inputValue: prevMessage,
//       inputPlaceholder: placeholder,
//       showCancelButton: true,
//       inputValidator: (value) => {
//         return new Promise((resolve) => {
//           if (value) {
//             resolve();
//           } else {
//             resolve('Please add comment');
//           }
//         });
//       },
//       customClass: 'required'
//     });
//   }
}
