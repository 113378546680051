import { Component, OnInit, ChangeDetectorRef, NgZone, AfterViewChecked, AfterContentInit } from '@angular/core';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, AfterContentInit {
  show: boolean;
  constructor(private loaderService: LoaderService, private _changeDetectionRef: ChangeDetectorRef) {}
  ngOnInit() {
    this.loaderService.isLoading.subscribe((value) => {
      setTimeout(() => {
        this.show = value;
      }, 0);
    });
  }

  ngAfterContentInit(): void {
    // your code
    this._changeDetectionRef.detectChanges();
  }
}
