import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import * as $ from 'jquery';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  sideBar: boolean = false;
  play: boolean = false;
  loginForm: FormGroup;
  needRedirect: boolean = environment['mainPage']['redirect-urls'].length > 0;
  redirectUrls: Array<{name:string,url:string}> = environment['mainPage']['redirect-urls']
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private fb: FormBuilder,
  private authService: MsalService,
  private router: Router) {
    $(window).scroll(() => {
      let scroll = $(window).scrollTop();

      if (scroll >= 42) {
        $(".burger-menu").addClass("stickHeader");
      } else {
        $(".burger-menu").removeClass("stickHeader");
      }
      if (scroll > 200) {
        $('.sticky-navbar').addClass('sticky-navbar--show');
      }else{
        $('.sticky-navbar').removeClass('sticky-navbar--show');
      }
      if(scroll>=300){
        $('.go-to-top').addClass('go-top-fadeInRight');
      }else{
        $('.go-to-top').removeClass('go-top-fadeInRight');
      }


    })
    $(window).on("resize scroll", function () {
      $('.add-animate').each(function () {
        const animateType = $(this).attr('data-animated'),
          docViewTop = $(window).scrollTop(),
          docViewBottom = docViewTop + $(window).height(),
          elementTop = $(this).offset().top,
          elementBottom = elementTop + $(this).height();

        if (((elementBottom > docViewTop) && (elementTop < docViewBottom)) === true) {
          $(this).addClass('animated ' + animateType).removeClass('add-animate');
        }
      });
    });
  }

  ngOnInit(): void {
    $('.go-to-top').removeClass('go-top-fadeInRight');
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: ['',Validators.required],
      password: ['',Validators.required],
    });

  }

  // hideLoder() {
  //   let item = document.getElementById('top');
  //   setTimeout(() => {
  //     // item?.style.display ="none";
  //   }, 2000)
  // }

  openContactUs() {
    // console.log('contact-us')
    this.router.navigateByUrl('/contact-us');
  }

  /** Function for login */
  doLogin() {

    // this.router.navigateByUrl("/login");
    localStorage.setItem('tryLogin','true');
    window.location.href = environment['loginUrl'] + '&redirect_uri=' + encodeURIComponent(environment['redirectUrl']);
  }

  /** Go to top button press */
  goToTop() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $('.go-to-top').removeClass('go-top-fadeInRight');
  }

  /** function for play video */
  playVideo() {
    this.play = !this.play;
  }
}