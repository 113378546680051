import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { InfoPageComponent } from './pages/info-page/info-page.component';
import { StartGurad } from './core/guards/start.guard';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { MainGuard } from './core/guards/main.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'redirecting',
    canActivate: [StartGurad],
    pathMatch: 'full',
  },
  // { path: 'redirecting', component: InfoPageComponent },
  {
    path: 'main',
    component: LandingPageComponent,
    canActivate: [MainGuard]
  },
  {
    path: 'not-approved',
    component: InfoPageComponent,
    data: {
      title: 'Need Approval!'
    }
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [StartGurad],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
      }
    ]
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./layouts/admin-page/admin-page.module').then(m => m.AdminPageModule)
  // },
  {
    path: '**',
    redirectTo: 'redirecting'
  }
];
