import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ApiCacheService {
    private storage: Storage;

    constructor(@Inject('API_CACHE_STORAGE')storage: Storage) {

        this.storage = storage;
    }

    public get(cacheKey: string): any | null {
        const cachedItem = this.storage.getItem(cacheKey);
        return cachedItem ? JSON.parse(cachedItem) : null;
    }

    public set(cacheKey: string, data: any) {
        this.storage.setItem(cacheKey, JSON.stringify(data));
    }

    async fetch(url: string, params: Record<string, any> = {}, options: RequestInit = {}): Promise<any> {
        const cacheKey = url
        const cachedData = this.get(cacheKey);

        if (cachedData) {
            console.log('Returning cached response',cachedData);
            return cachedData;
        }

        const queryParams = new URLSearchParams(params).toString();
        const fullUrl = queryParams ? `${url}?${queryParams}` : url;

        const response = await fetch(fullUrl, { ...options, method: 'GET' });
        const data = await response.json();

        this.set(cacheKey, data);

        return data;
    }

    clear(url?: string, params?: Record<string, any>) {
        if (url && params) {
            const cacheKey = url
            this.storage.removeItem(cacheKey);
        } else {
            this.storage.clear();
        }
    }
}
