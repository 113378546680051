import { Component, Inject, OnInit } from '@angular/core';
import { AuthUserService } from 'app/core/services/auth-user.service';
import { AuthError, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  constructor(private authUserService: AuthUserService,
              private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) { }

  ngOnInit() { 
    document.body.style.paddingTop = '0px';
  }

  getProfile() {
    this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
    this.authService.acquireTokenSilent({ scopes: ['User.Read'] }).subscribe((result: AuthenticationResult) => {
      this.authUserService.login({ token: result.idToken, platform: 2 });
    },
      ((err) => {
        if (err instanceof AuthError && this.msalGuardConfig.authRequest) {
          // fallback to interaction when silent call fails
          return this.authService.acquireTokenRedirect({scopes: ['User.Read']} as RedirectRequest);
        }
      }
      ));
  }
}
