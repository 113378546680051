import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APIS, MESSAGES } from 'app/common/constants';
import { InspectionVersion } from 'app/core/interfaces/InspectionVersion';
import { AlertService } from 'app/core/services/alert.service';
import { HttpService } from 'app/core/services/http.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-version-history',
    templateUrl: './version-history.component.html',
    styleUrls: ['./version-history.component.css']
})
export class VersionHistoryComponent implements OnInit {
    @Input() qcDataID: number;

    selectAll: boolean = false;
    reportVersions: InspectionVersion[] = [];
    selectedVersions: Set<number> = new Set();
    downloadLinks: HTMLIFrameElement[] = [];
    moment = moment;
    isLoading = false;
    constructor(private activeModal: NgbActiveModal, private httpService: HttpService, public alertService: AlertService) { }

    ngOnInit(): void {
        const inspectionVersionUrl = APIS.INSPECTION_VERSION.replace('{0}', String(this.qcDataID));
        this.isLoading = true;
        Swal.fire('Please wait');
        Swal.showLoading();
        this.httpService.getData(inspectionVersionUrl).subscribe(res => {
            this.reportVersions = res.data;
        }, () => {
            this.alertService.errorToast(MESSAGES.ERRORS.FailedToLoadInspectionReportVersion);
            Swal.close();
            this.close();
        }, () => {
            this.isLoading = false;
            Swal.close();
        });
    }

    close() {
        this.downloadLinks.forEach(l => l.remove());
        this.activeModal.close();
    }

    downloadReports(e: MouseEvent) {
        e.preventDefault();

        if (this.selectedVersions.size > 0) {
            const urls = this.reportVersions.filter(r => this.selectedVersions.has(r.id)).map(r => r.url);
            urls.forEach(url => {
                const iframe = document.createElement("iframe");
                iframe.setAttribute("sandbox", "allow-downloads allow-scripts");
                iframe.src = url;
                iframe.setAttribute("style", "display: none");
                this.downloadLinks.push(iframe);
                document.body.appendChild(iframe);

            });
        }
    }
    isSelected(id: number) {
        return this.selectedVersions.has(id);
    }

    onCheckboxChange(id: number, isChecked: boolean) {
        if (isChecked) {
            this.selectedVersions.add(id);
        } else {
            this.selectedVersions.delete(id);
        }
        this.selectAll = this.reportVersions.length === this.selectedVersions.size;
    }

    selectAllVersions(allSelected: boolean) {
        if (allSelected) {
            this.selectedVersions = new Set(this.reportVersions.map(r => r.id));
        } else {
            this.selectedVersions = new Set();
        }
    }
}